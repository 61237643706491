import React from 'react';
import { connect } from 'react-redux';
import { setTitle, setMessage, sendMessage, sendPushNotificationGoogle } from '../../actions/index.js';

const BroadcastMessage = ({ title, message, setTitle, setMessage, sendMessage }) => {
    const handleTitleChange = (e) => {
        setTitle(e.target.value);
    };

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    };

    const handleSendMessage = () => {
        if (!title.trim() || !message.trim()) {
            alert("Both Title and Message fields are required!");
            return;
        }
        //sendMessage(title, message);
        sendPushNotificationGoogle(message);
        addBroadcastMessage(title, message);
    };

    return (
        <div style={styles.container}>
            <h2>Send Broadcast Message</h2>
            <input
                type="text"
                placeholder="Message Title..."
                value={title}
                onChange={handleTitleChange}
                style={styles.inputTitle}
            />
            <textarea
                placeholder="Message text"
                value={message}
                onChange={handleMessageChange}
                style={styles.textarea}
            ></textarea>
            <button
                onClick={handleSendMessage}
                className="btn btn-yellow"
                style={{
                    ...styles.button,
                    backgroundColor: title.trim() && message.trim() ? '#FFD700' : '#ccc',
                    cursor: title.trim() && message.trim() ? 'pointer' : 'not-allowed'
                }}
                disabled={!title.trim() || !message.trim()} // Onemogućava dugme ako su polja prazna
            >
                SEND MESSAGE
            </button>
        </div>
    );
};

const styles = {
    container: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '8px',
        margin: 'auto',
        backgroundColor: '#f9f9f9',
    },
    inputTitle: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        height: '200px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    button: {
        width: '50%',
        padding: '10px',
        fontWeight: 'bold',
    },
};

// Mapiranje Redux stanja na props
const mapStateToProps = (state) => ({
    title: state.broadcast.title,
    message: state.broadcast.message,
});

// Mapiranje akcija na props
const mapDispatchToProps = (dispatch) => ({
    setTitle: (title) => dispatch(setTitle(title)),
    setMessage: (message) => dispatch(setMessage(message)),
    sendMessage: () => dispatch(sendMessage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BroadcastMessage);

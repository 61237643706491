import React, { Component } from 'react';
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { isAndroid } from 'react-device-detect';
import { isWindows } from 'react-device-detect';
import { fetchGooglePass } from "../../actions/index"
import QRCode from "react-qr-code";
// import PropTypes from "prop-types"

 class AddGooglePass extends Component {

     componentWillMount() {
      const query = new URLSearchParams(this.props.location.search);
      this.props.fetchGooglePass(query.get('id'));
     }

     renderContent = () => {

         const saveUrl = this.props.saveUrl;

         if (isAndroid || isWindows) {
             return (
                 <div className="text-center">
                     <a href={saveUrl}>
                         <img  src="../images/wallet-button.png" width="50%"></img>
                     </a>
                 </div>
             );
         }
         return <div className="alert alert-warning"  > Currently available only for Android </div>



     }



    render() {

        return this.renderContent()
    }
}



function mapStateToProps(state) {
    return {
        saveUrl: state.pass.saveUrl,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchGooglePass: fetchGooglePass,
        // callMessage: callMessage 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddGooglePass)

import React from 'react';
import BroadcastMessage from './BroadcastMessage';
import UserStats from './UserStats';
import PlanStatus from './PlanStatus';
import PreviousMessages from './PreviousMessages';

const Broadcast = () => {

    const dummyMessages = [
        {
            date: "2024-02-01",
            title: "System Update",
            text: "A new system update has been deployed successfully.",
            status: "sent",
        },
        {
            date: "2024-01-28",
            title: "Maintenance Alert",
            text: "Scheduled maintenance will occur this Sunday.",
            status: "sent",
        },
        {
            date: "2024-01-20",
            title: "Welcome Message",
            text: "Welcome to our platform! Enjoy your stay.",
            status: "sent",
        },
    ];

    return (
        <div className="container-fluid ">
            <div className="row">
                {/* Leva kolona */}
                <div className="col-lg-8 col-md-12 mb-2">
                    <BroadcastMessage />
               </div>
             {/* Desna kolona */}
                <div className="col-lg-4 col-md-12 mb-2">
                    <UserStats />
                    <div className="mt-4">
                        <PlanStatus />
                    </div>
                </div>
            </div>
            <div className="row ms-0 me-0">
                <PreviousMessages messages={dummyMessages} filterMessages={() => { }} />
            </div>
        </div>
    );
};

export default Broadcast;
import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Navbar from "./navbar/navbar";
import SideBar from "./sidebar/sidebar";
import SupplierList from "./supplier/supplier_list";
import Cards from "./card/cards";
import Broadcast from "./broadcast/Broadcast";
import Print from "./print/print";
import Scan from "./scan/scan";
import Settings from "./settings/settings";
import GetGooglePass from "./gen_pass/gen_pass"
import CardDetail from "./card/card-detail";
import PropTypes from "prop-types";
import Banner from '../assets/banner.svg';
import BreadCrumbs from './BreadCrumbs'

export default class App extends Component {
   
    componentWillMount() {
         
    }
  render() {
    const supplierId = this.props.match.params.supplierId === undefined ? 0 : this.props.match.params.supplierId;
    return (
        <div className="container-fluid" style={{ maxWidth: '1920px' }}>
       <div className="d-flex">
        <SideBar/>
        <div className="flex-grow-1">
            <div className="d-flex flex-column ">
                        <BreadCrumbs />
            <div className="row mt-4 ">
                <div className="col-9">
                <div className="flex-grow-1" >
                    <Route path="/web/cards/:supplierId?/:page?" component={Cards} />
                    <Route path="/web/print" component={Print} />
                    <Route path="/web/broadcast" component={Broadcast} />
                    <Route path="/web/scan" component={Scan} />
                    <Route path="/web/settings" component={Settings} />
                    <Route path="/web/carddetail/:cardId" component={CardDetail} />
                        </div>

                </div>
                <div className="col-3">
                  <img className="align-self-center img-fluid" src={Banner} alt="logo" />
                </div>
                </div>
            </div>
        </div>
            </div>
    </div>
    );
  }
}

App.contextTypes = {
    router: PropTypes.object
}
import axios from "axios";

//export const HOSTNAME = "http://192.168.0.12"
//export const HOSTNAME = "http://localhost"
//export const HOSTNAME = "http://23.88.56.36"
export const HOSTNAME = "http://" + window.location.hostname;
var API_URL_temp;
console.log("hostname: " + HOSTNAME);

if (HOSTNAME.includes("stampedo-card.de")) {
  API_URL_temp = "https://api.stampedo-card.de";
}
else {
    API_URL_temp = HOSTNAME + ":3300";
}

const API_URL = API_URL_temp
const GETUSERID_URL = "/api/getuserid"
const CARD_URL = "/api/card";
const BAR_GRAPH_URL ="/api/bargraphdata"
const LINE_GRAPH_URL ="/api/linegraphdata"
const GAUGE_GRAPH_URL ="/api/gaugegraphdata"
const ACCES_TOKEN_URL = "/auth/token";
const SIGNUP_URL = "/auth/signup";
const CARD_CRUD_URL = "/api/admin/cards";
const SUPPLIER_CRUD_URL = "/api/suppliers";
const GOOGLE_PASS_CRUD_URL = "/pub/gengooglecard/";
const SETTINGS_URL = "/api/settings"
const CROP_IMAGE_UPLOAD_URL = "/api/upload"
const BROADCAST_MESSAGE_URL = "/api/addBroadcastMessage";
const READ_MESSAGES_URL = "/api/readMessages";
const READ_MESSAGE_URL = "/api/readMessage";


export const FETCH_CARD_LIST = "FETCH_CARD_LIST";
export const FETCH_ADMIN_CARD_LIST = "FETCH_ADMIN_CARD_LIST";
export const CARD_DETAIL = "CARD_DETAIL";
export const GET_GOOGLE_PASS = "GET_GOOGLE_PASS";
export const PUSH_GOOGLE_NOTIFICATION = "PUSH_GOOGLE_NOTIFICATION";  
export const FETCH_SUPPLIER_LIST = "FETCH_SUPPLIER_LIST";
export const SUPPLIER_DETAIL = "SUPPLIER_DETAIL";
export const ACCES_TOKEN = "ACCES_TOKEN";
export const LOG_OUT = "LOG_OUT";
export const SIGN_UP = "SIGN_UP";
export const SIGN_UP_RESET = "SIGN_UP_RESET";

export const USERID_LOADED = "USERID_LOADED";

export const CARD_CREATE = "CARD_CREATE";
export const CARD_UPDATE = "CARD_UPDATE";
export const CARD_DELETE = "CARD_DELETE";

export const SUPPLIER_CREATE = "SUPPLIER_CREATE";
export const SUPPLIER_UPDATE = "SUPPLIER_UPDATE";
export const SUPPLIER_DELETE = "SUPPLIER_DELETE";

export const SCAN_SCANNED_CARD = "SCAN_SCANNED_CARD";
export const SCAN_PASS_DISPLAYED = "SCAN_PASS_DISPLAYED";
export const SCAN_STAMP_ADDED = "SCAN_STAMP_ADDED";
export const SCAN_CARD_USED = "SCAN_CARD_USED";

export const SETTINGS_LOADED = "SETTINGS_LOADED";
export const SETTINGS_SAVED = "SETTINGS_SAVED";
export const SETTINGS_LOGO_SELECTED = "SETTINGS_LOGO_SELECTED";
export const SETTINGS_MS_HEADER_SELECTED = "SETTINGS_MS_HEADER_SELECTED";
export const SETTINGS_CROP_SET = "SETTINGS_CROP_SET";
export const SETTINGS_CROP_IMAGE_SET = "SETTINGS_CROP_IMAGE_SET";
export const SETTINGS_CROP_MS_HEADER_SET = "SETTINGS_CROP_MS_HEADER_SET";
export const SETTINGS_CROP_IMAGE_UPLOADED = "SETTINGS_CROP_IMAGE_UPLOADED";

// graph actions


export const UPDATE_BAR_CHART_DATA = 'UPDATE_BAR_CHART_DATA';
export const UPDATE_LINE_CHART_DATA = 'UPDATE_LINE_CHART_DATA';
export const UPDATE_GAUGE_DATA = 'UPDATE_GAUGE_DATA';
export const UPDATE_PAPER_GAUGE_DATA = 'UPDATE_PAPER_GAUGE_DATA';
export const UPDATE_DIGITAL_GAUGE_DATA = 'UPDATE_DIGITAL_GAUGE_DATA';

//write read broadcast messages
export const ADD_BROADCAST_MESSAGE = "ADD_BROADCAST_MESSAGE";
export const FETCH_USER_MESSAGES = "FETCH_USER_MESSAGES";
export const FETCH_SINGLE_MESSAGE = "FETCH_SINGLE_MESSAGE";

export function addBroadcastMessage(subject, messageBody) {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken) {
        token = JSON.parse(accessToken);
    }

    const request = axios.post(API_URL + BROADCAST_MESSAGE_URL, {
        subject,
        messageBody
    }, {
        headers: {
            "access-token": token.accessToken
        }
    });

    return {
        type: ADD_BROADCAST_MESSAGE,
        payload: request
    };
}

export function fetchUserMessages(userId) {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken) {
        token = JSON.parse(accessToken);
    }

    const request = axios.get(API_URL + READ_MESSAGES_URL + "/" + userId, {
        headers: {
            "access-token": token.accessToken
        }
    });

    return {
        type: FETCH_USER_MESSAGES,
        payload: request
    };
}

export function fetchSingleMessage(messageId) {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken) {
        token = JSON.parse(accessToken);
    }

    const request = axios.get(API_URL + READ_MESSAGE_URL + "/" + messageId, {
        headers: {
            "access-token": token.accessToken
        }
    });

    return {
        type: FETCH_SINGLE_MESSAGE,
        payload: request
    };
}




// Action to update bar chart data
export function updateBarChartData() {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + BAR_GRAPH_URL, {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: UPDATE_BAR_CHART_DATA,
        payload: request
    };
}

// Action to update line chart data
export function updateLineChartData() {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + LINE_GRAPH_URL, {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: UPDATE_LINE_CHART_DATA,
        payload: request
    };
}

// Action to update gauge data
export function updateGaugeData() {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + GAUGE_GRAPH_URL, {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: UPDATE_GAUGE_DATA,
        payload: request
    };
}
// Action to update paper gauge data
export function updatePaperGaugeData() {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + GAUGE_GRAPH_URL + "/paper", {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: UPDATE_PAPER_GAUGE_DATA,
        payload: request
    };
}

// Action to update digital gauge data
export function updateDigitalGaugeData() {
    const accessToken = localStorage.getItem("accessToken");
    let token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + GAUGE_GRAPH_URL + "/digital", {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: UPDATE_DIGITAL_GAUGE_DATA,
        payload: request
    };
}

///Cupplier Action Start

export function userIdLoaded() { 

    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + GETUSERID_URL, {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: USERID_LOADED
        ,
        payload: request
    };


}

export function cropImageUploaded(blob) {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
     
    
    let fileName = blob.name;
    console.log("Filename: " + fileName);
    let file = new File([blob], fileName, { type: "image/jpeg" });
    let formData = new FormData();
    formData.append('file', file, blob.name);
    formData.append('name', 'name of the file');

   
    /*
    axios.post("https://httpbin.org/post", formData, {
        headers: {
            'content-ype': `multipart/form-data`
        }
    })
        .then(res => { console.log("success on httpbin"); console.log(res) } )
        .catch(err => { console.log("error: "); console.log(err) });
        */
    var request = axios.post(API_URL + CROP_IMAGE_UPLOAD_URL, formData, {
        headers: {
            'Content-Type': `multipart/form-data`,
            
            "access-token": token.accessToken
        }

    });

    return {
        type: SETTINGS_CROP_IMAGE_UPLOADED,
        payload: request
    };
}


export function cropHasBeenSet(crop) {
   
    return {
        type: SETTINGS_CROP_SET
        ,
        payload: crop
    };
}

export function croppedImageSet(cropImageUrl) {
 
    return {
        type: SETTINGS_CROP_IMAGE_SET
        ,
        payload: cropImageUrl
    };
}


export function settingsLoaded() {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    const request = axios.get(API_URL + SETTINGS_URL, {
        headers: {
            "access-token": token.accessToken
        }});
    return {
        type: SETTINGS_LOADED
        ,
        payload: request
    };
}

export function settingsLogoSelected(logo_img) {
    return {
        type: SETTINGS_LOGO_SELECTED,
        payload: logo_img
    };
}

export function settingsMsHeaderSelected(logo_img) {
    return {
        type: SETTINGS_MS_HEADER_SELECTED,
        payload: logo_img
    };
}


export function settingsSaved(props) {
   

    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    let updatedCardTemplate =
    {
        ...props.cardtemplate,
        ...props.mstemplate
        
    }
    console.log("updatedCardTemplate:");
    console.log(updatedCardTemplate);
    var request = axios.put(API_URL + SETTINGS_URL, updatedCardTemplate, {
        headers: {
            "access-token": token.accessToken
        }
    });
    return {
        type: SETTINGS_SAVED,
        payload: request
    };
}


export function cardUsed(props) {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    let updateCard =
    {
        ...props,
        used: true 
    }

    var request = axios.put(API_URL + CARD_CRUD_URL, updateCard, {
        headers: {
            "access-token": token.accessToken
        }
    });

    return {
        type: SCAN_CARD_USED,
        payload: request
    };
}


export function stampAdded(props) {
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    let updateCard =
    {
        ...props,
        currentStamps: props.currentStamps+1
    }
  
    var request = axios.put(API_URL + CARD_CRUD_URL, updateCard, {
        headers: {
            "access-token": token.accessToken
        }
    });

    return {
        type: SCAN_STAMP_ADDED,
        payload: request
    };
}



export function displayScannedCard(cardId) {
    console.log("this function is called! :)");
    const request = axios.get(API_URL + CARD_URL + "/" + cardId);
    return {
        type: SCAN_PASS_DISPLAYED,
        payload: request
    };
}


export function cardIdScanned(cardID) {
    console.log("Action Data is: " + cardID);
    return {
        type: SCAN_SCANNED_CARD,
        payload: cardID
    };
}



export function fetchSuppliers() {
  const request = axios.get(API_URL + SUPPLIER_CRUD_URL);
  return {
    type: FETCH_SUPPLIER_LIST,
    payload: request
  };
}

export function supplierDetail(supplierId) {
  const request = axios.get(API_URL + SUPPLIER_CRUD_URL + "/" + supplierId);
  return {
    type: SUPPLIER_DETAIL,
    payload: request
  };
}

export function addSupplier(props) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
    }
    var request = axios.post(API_URL + SUPPLIER_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: SUPPLIER_CREATE,
    payload: request
  };
}

export function updateSupplier(props) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
    }
    console.log("UPDATE FOLLOWING DATA: ")
    console.log(props);
  var request = axios.put(API_URL + SUPPLIER_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: SUPPLIER_UPDATE,
    payload: request
  };
} 

export function deleteSupplier(id) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.delete(API_URL + SUPPLIER_CRUD_URL + "/" + id, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: SUPPLIER_DELETE,
    payload: request
  };
}
///Supplier Action end

///Card Action Start
export function fetchCards(supplierId, page) {
  const request = axios.get(
    API_URL + CARD_URL + "/" + supplierId + "/" + page
  );
  return {
    type: FETCH_CARD_LIST,
    payload: request
  };
}

export function fetchAdminCards(page) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios
    .get(API_URL + CARD_CRUD_URL + "/" + page, {
      headers: {
        "access-token": token.accessToken
      }
    })
    .catch(error => {
      console.log("Sorry, something is wrong: " + error);
      if (error.response) {
        // console.log(error.response.data.error_description);
        // console.log(error.response.status);
        // console.log(error.response.headers);
        // tokenData.accessToken = '';
        // tokenData.message = response.data.error_description
      } else {
        console.log("Error", error.message);
        accessToken.accessToken = "";
        accessToken.message = error.message;
      }
      console.log(error.config);
    });
  return {
    type: FETCH_ADMIN_CARD_LIST,
    payload: request
  };
}


export function fetchGooglePass(id) {
    //console.log(props);
    const request = axios.get(API_URL + GOOGLE_PASS_CRUD_URL + id );
    return {
        type: GET_GOOGLE_PASS,
        payload: request
    };


}




export function sendPushNotificationGoogle(message) {
    console.log("triggered Send push nottifications");
    var accessToken = localStorage.getItem("accessToken");
    var token = "";
    if (accessToken != null) {
        token = JSON.parse(accessToken);
    }
    var request = axios.post(API_URL+'/pub/sendnotification', { message }, {
            headers: {
                "access-token": token.accessToken
            }
        });

        return {
            type: PUSH_GOOGLE_NOTIFICATION,
            payload: request
        };
};





export function addCard(props) {
 
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.post(API_URL + CARD_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: CARD_CREATE,
    payload: request
  };
}

export function updateCard(props) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.put(API_URL + CARD_CRUD_URL, props, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: CARD_UPDATE,
    payload: request
  };
}

export function deleteCard(id) {
  var accessToken = localStorage.getItem("accessToken");
  var token = "";
  if (accessToken != null) {
    token = JSON.parse(accessToken);
  }
  var request = axios.delete(API_URL + CARD_CRUD_URL + "/" + id, {
    headers: {
      "access-token": token.accessToken
    }
  });

  return {
    type: CARD_DELETE,
    payload: request
  };
}

export function cardDetail(cardId) {
    console.log("theId is: " + cardId);
  const request = axios.get(API_URL + CARD_URL + "/" + cardId);
  return {
    type: CARD_DETAIL,
    payload: request
  };
}

export function signUpReset() {

    return {
        type: SIGN_UP_RESET,
        payload: null
    };
}

export function signup(props) {

    const data = {
        fullName: props.Name,
        lastName: props.Surname,
        email: props.Email,
        password: props.Password
    };
    console.log("post api: " + API_URL + SIGNUP_URL );


    //console.log(data);
   
    var request = axios.post(API_URL + SIGNUP_URL, data);
    return {
        type: SIGN_UP,
        payload: request
    };
}


export function login(props) {
  const data = {
    email: props.Email,
    password: props.Password
  };

  var request = axios.post(API_URL + ACCES_TOKEN_URL, data);
  return {
    type: ACCES_TOKEN,
    payload: request
  };
}

export function logout(props) {

    return {
        type: LOG_OUT,
        payload: null
    };

}

// broadcast

export const SET_TITLE = 'SET_TITLE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SEND_MESSAGE = 'SEND_MESSAGE';

export const setTitle = (title) => ({
    type: SET_TITLE,
    payload: title,
});

export const setMessage = (message) => ({
    type: SET_MESSAGE,
    payload: message,
});

export const sendMessage = () => ({
    type: SEND_MESSAGE,
});



export const SET_AVAILABLE_USERS = 'SET_AVAILABLE_USERS';

export const setAvailableUsers = (count) => ({
    type: SET_AVAILABLE_USERS,
    payload: count,
});

export const SET_REMAINING_MESSAGES = 'SET_REMAINING_MESSAGES';
export const SET_ACTIVE_PLAN = 'SET_ACTIVE_PLAN';

export const setRemainingMessages = (count) => ({
    type: SET_REMAINING_MESSAGES,
    payload: count,
});

export const setActivePlan = (plan) => ({
    type: SET_ACTIVE_PLAN,
    payload: plan,
});

// actions/index.js

export const SET_MESSAGES = 'SET_MESSAGES';
export const FILTER_MESSAGES = 'FILTER_MESSAGES';

export const setMessages = (messages) => ({
    type: SET_MESSAGES,
    payload: messages,
});

export const filterMessages = (filter) => ({
    type: FILTER_MESSAGES,
    payload: filter,
});

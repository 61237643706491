import { combineReducers } from 'redux';
import Suppliers from "./reducer-suppliers";
import Cards from "./reducer-cards";
import Graphs from "./reducer-graphs";
import Pass from "./reducer-pass";
import Print from "./reducer-print";
import Scan from "./reducer-scan";
import Settings from "./reducer-settings";
import AccessToken from "./reducer-token";
import Broadcast from "./reducer-broadcast";
import BroadcastMsgs from "./reducer-broadcast-msgs";
import { reducer as formReducer } from 'redux-form';


const rootReducer = combineReducers({
  suppliers: Suppliers,
  cards: Cards,
  graphs: Graphs,
  accessToken: AccessToken,
  form: formReducer,
  pass: Pass,
  print: Print,
  scan: Scan,
  settings: Settings,
  broadcast: Broadcast,
  broadcastMsgs: BroadcastMsgs

});

export default rootReducer;
import React from "react";
import { LineChart, Line, ResponsiveContainer} from "recharts";

const Chart = ({ data }) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data}>
                <Line type="monotone"
                    dataKey="value"
                    stroke="#FFFFFF"
                    strokeWidth={2}
                    dot={false}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};
//Chart.demoUrl = "https://codesandbox.io/p/sandbox/tiny-line-chart-5f5vq6";
export default Chart;

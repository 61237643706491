import React, { Component } from 'react';
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import QRCode from "react-qr-code";
import { userIdLoaded, sendPushNotificationGoogle } from "../../actions/index"
// import PropTypes from "prop-types"

 class Print extends Component {

    componentWillMount() {

        this.props.userIdLoaded();
    }
     handleSendNotification = () => {
         const message = 'Some other custom message notification message here';
         this.props.sendPushNotificationGoogle(message);
     };

    render() {
        const supplierId = this.props.match.params.supplierId === undefined ? 0 : this.props.match.params.supplierId;
        const page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
        const pathBase = window.location.origin;
        //const pathBase = HOSTNAME;
        let urlLink = pathBase + "/public/gengooglepass?id=" + this.props.userId;
        return (
            <div className="d-flex flex-column align-items-center justify-top-center vh-100 text-center">
                <div className="alert alert-success w-75 mb-4">
                    Please scan the master code
                </div>

                <div className="mb-4">
                    <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={urlLink}
                        viewBox={`0 0 128 128`}
                    />
                </div>

                <button
                    className="btn btn-yellow"
                    onClick={this.handleSendNotification}
                >
                    Send Push Notification
                </button>
            </div>
           
        );
    }
}

function mapStateToProps(state) {
    return {
        userId: state.print.userId
        
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        
        
        userIdLoaded: userIdLoaded,
        sendPushNotificationGoogle: sendPushNotificationGoogle 
    

        // callMessage: callMessage 
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Print)
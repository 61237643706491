
import React, { Component } from 'react';
import './sidebars.css';
import StampedoLogo from '../../assets/gpt_logo.png';

export default class SideBar extends Component {
    constructor(props) {
        super(props);

    }
    render() {
    return (

    <div className="d-flex flex-column justify-content-between flex-shrink-0 p-3 bg-body-tertiary" style={{ width: "235px", height: "100vh" }} >
            <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
            <img src={StampedoLogo} alt="Stampedo Logo" width="auto" height="32"/>
                 <span className="fs-4">Stampedo</span>
            </a>
            <hr/>
            <ul className="nav nav-pills flex-column mb-auto">
                
            <li>
                    <a href="/web/cards" className="nav-link link-body-emphasis">
                    <i className="bi bi-speedometer2 pe-none me-2 active" width="16" height="16"></i>
                    Dashboard
                </a>
            </li>
            <li>
                    <a href="/web/broadcast" className="nav-link link-body-emphasis">
                    <i className="bi bi-broadcast-pin pe-none me-2" width="16" height="16"></i>
                    Broadcast
                </a>
                </li>
                <li>
                    <a href="/web/print" className="nav-link link-body-emphasis">
                        <i className="bi bi-broadcast-pin pe-none me-2" width="16" height="16"></i>
                        print
                    </a>
                </li>
            <li>
                    <a href="/web/settings" className="nav-link link-body-emphasis">
                    <i className="bi bi-sliders2-vertical pe-none me-2" width="16" height="16"></i>
                    Settings
                </a>
            </li>
           
        </ul>
        <hr/>
                <div className="dropdown">
                    <a href="#" className="d-flex align-items-center link-body-emphasis text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2"/>
                        <strong>mdo</strong>
                    </a>
                    <ul className="dropdown-menu text-small shadow">
                        <li><a className="dropdown-item" href="#">New project...</a></li>
                        <li><a className="dropdown-item" href="#">Settings</a></li>
                        <li><a className="dropdown-item" href="#">Profile</a></li>
                        <li><hr className="dropdown-divider"/></li>
                        <li><a className="dropdown-item" href="#">Sign out</a></li>
                </ul>
            </div>
        </div>
 );
 }



}
import React from "react";
import Vector from "./assets/Vector.png";
import Frame from "./assets/Frame.png";
import ArrowRight from "./assets/arrow-right.png";
import Chart from "./Chart";
import { LineChart, Line, ResponsiveContainer, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const Gauge = ({ dark_mode, title, data }) => {
    const { total, history } = data;

    var dark = "";
    if (dark_mode === true) {
        dark = "-dark";
    }

    const chartData = history.map((value, index) => ({
        time: `T-${history.length - index}`,
        value,
    }));

    return (
        <div className={`card mx-auto mt-3 card-container${dark}`}>
            <div style={{ position: "relative" }}>
                <div className={`left-svg-background${dark}`} />
                <div className={`right-svg-background${dark}`} />
            </div>

            <div className="card-body" style={{ zIndex: "1" }}>
                <div className="d-flex justify-content-between align-items-start relative">
                    <div className={`icon-container${dark} d-flex justify-content-center align-items-center rounded-2`}>
                        <img
                            src={Vector}
                            alt="icon"
                            className={`img-fluid small-image-style${dark}`}
                        />
                    </div>
                    <div>
                        <button className="btn text-white hover-button button-text">
                            Monthly
                        </button>
                        <button className="btn text-white hover-button button-text">
                            Yearly
                        </button>
                    </div>
                </div>
                <p className="text-white number-style">{total}</p>
                <p className="description">{title}</p>
                <button className={`down-arrow-style${dark}`}>
                    <img
                        src={Frame}
                        alt="icon"
                        className="img-fluid"
                        style={{
                            filter: "brightness(0) invert(1)",
                            width: "13.2px",
                            height: "14px",
                        }}
                    />
                </button>
                <div
                    className="position-absolute"
                    style={{ width: "50%", height: "100px", top: "84px", right: "20px" }}
                >
                    <Chart data={chartData} />
                    <div
                        className="position-absolute"
                        style={{ top: "110px", right: "0" }}
                    >
                        <button className="btn hover-button detail-button">
                            <div className="text-style">Details</div>
                            <img
                                src={ArrowRight}
                                alt=""
                                className="img-fluid ms-1"
                                style={{
                                    verticalAlign: "middle",
                                    width: "22px",
                                    height: "20px",
                                }}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Gauge;

import * as actions from "../actions/index";

const INITIAL_STATE = {
    barChartData: [
        { name: "Nr Cards", uv: 0 },
        { name: "No Stamps", uv: 0 },
        { name: "1 Stamp", uv: 0 },
        { name: "2 Stamps", uv: 0 },
        { name: "3 Stamps", uv: 0 },
        { name: "4 Stamps", uv: 0 },
        { name: "5 Stamps", uv: 0 },
        { name: "6 Stamps", uv: 0 },
    ],

    lineChartData: [
        { day: 1, paper: 18, digital: 20 },
        { day: 2, paper: 12, digital: 22 },
        { day: 3, paper: 18, digital: 0 },
        { day: 4, paper: 18, digital: 20 },
        { day: 5, paper: 18, digital: 70 },
        { day: 6, paper: 18, digital: 50 },
        { day: 7, paper: 18, digital: 30 },
    ],

    gaugeData: {
        total: 1200,
        history: [200, 1200, 300, 400, 600],
    },

    paperGaugeData: {
        total: 0,
        history: [],
    },

    digitalGaugeData: {
        total: 0,
        history: [],
    }
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${actions.UPDATE_BAR_CHART_DATA}_PENDING`:
            return { ...state };
        case `${actions.UPDATE_BAR_CHART_DATA}_FULFILLED`:
            return {
                ...state,
                barChartData: action.payload.data,
            };
        case `${actions.UPDATE_BAR_CHART_DATA}_REJECTED`:
            return {
                ...state,
                sageUrl: "error",
            };

        case `${actions.UPDATE_LINE_CHART_DATA}_PENDING`:
            return { ...state };
        case `${actions.UPDATE_LINE_CHART_DATA}_FULFILLED`:
            console.log("LINE data: ", action.payload.data);
            return {
                ...state,
                lineChartData: action.payload.data,
            };
        case `${actions.UPDATE_LINE_CHART_DATA}_REJECTED`:
            return {
                ...state,
                sageUrl: "error",
            };

        case `${actions.UPDATE_GAUGE_DATA}_PENDING`:
            return { ...state };
        case `${actions.UPDATE_GAUGE_DATA}_FULFILLED`:
            console.log("GAUGE data: ", action.payload.data);
            return {
                ...state,
                gaugeData: action.payload.data,
            };
        case `${actions.UPDATE_GAUGE_DATA}_REJECTED`:
            return {
                ...state,
                sageUrl: "error",
            };

        case `${actions.UPDATE_PAPER_GAUGE_DATA}_PENDING`:
            return { ...state };
        case `${actions.UPDATE_PAPER_GAUGE_DATA}_FULFILLED`:
            console.log("PAPER GAUGE data: ", action.payload.data);
            return {
                ...state,
                paperGaugeData: action.payload.data,
            };
        case `${actions.UPDATE_PAPER_GAUGE_DATA}_REJECTED`:
            return {
                ...state,
                sageUrl: "error",
            };

        case `${actions.UPDATE_DIGITAL_GAUGE_DATA}_PENDING`:
            return { ...state };
        case `${actions.UPDATE_DIGITAL_GAUGE_DATA}_FULFILLED`:
            console.log("DIGITAL GAUGE data: ", action.payload.data);
            return {
                ...state,
                digitalGaugeData: action.payload.data,
            };
        case `${actions.UPDATE_DIGITAL_GAUGE_DATA}_REJECTED`:
            return {
                ...state,
                sageUrl: "error",
            };

        default:
            return state;
    }
}

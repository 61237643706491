// Watermark.js

import React from 'react';

const Watermark = () => {
    const isLocalhost = window.location.hostname === 'localhost' && window.location.port === '3000';
    if (isLocalhost) return null;

  return (
    <div style={styles.watermark}>
      DRAFT / Under Construction
    </div>
  );
};

const styles = {
  watermark: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '5rem',
    color: 'rgba(0, 0, 0, 0.1)', // Siva boja sa providnošću
    textTransform: 'uppercase',
    fontWeight: 'bold',
    zIndex: 9999, // Postavlja watermark iznad svih elemenata
    pointerEvents: 'none', // Omogućava interakciju sa elementima ispod
    transform: 'rotate(-30deg)', // Rotira tekst pod uglom
    whiteSpace: 'wrap',
  },
};

export default Watermark;

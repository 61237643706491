// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* breadcrumbs.css */

.breadcrumbs-container {
    height: 68px; /* Podesite ovu visinu da odgovara visini gornjeg dela sidebar-a */
    background-color: #f8f9fa; /* Koristite istu pozadinsku boju kao sidebar */
    display: flex;
    align-items: center;
    padding: 0 16px;
    /* border-bottom: 1px solid #dee2e6;  Opciono, dodaje donju liniju za razdvajanje  */
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '>';
    margin: 0 8px;
    color: #6c757d;
}

.breadcrumb {
    margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/breadcrumbs.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB;IACI,YAAY,EAAE,kEAAkE;IAChF,yBAAyB,EAAE,+CAA+C;IAC1E,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,oFAAoF;AACxF;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["/* breadcrumbs.css */\n\n.breadcrumbs-container {\n    height: 68px; /* Podesite ovu visinu da odgovara visini gornjeg dela sidebar-a */\n    background-color: #f8f9fa; /* Koristite istu pozadinsku boju kao sidebar */\n    display: flex;\n    align-items: center;\n    padding: 0 16px;\n    /* border-bottom: 1px solid #dee2e6;  Opciono, dodaje donju liniju za razdvajanje  */\n}\n\n.breadcrumb-item + .breadcrumb-item::before {\n    content: '>';\n    margin: 0 8px;\n    color: #6c757d;\n}\n\n.breadcrumb {\n    margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

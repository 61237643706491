import React, { useState } from 'react';
import { connect } from 'react-redux';
import { filterMessages } from '../../actions/index.js';

const PreviousMessages = ({ messages, filterMessages }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleFilterClick = (filter) => {
        filterMessages(filter);
    };

    const filteredMessages = messages.filter((message) =>
        message.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        message.text.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div style={styles.container}>
            <h3>Previous messages</h3>
            <input
                type="text"
                placeholder="Search messages"
                value={searchTerm}
                onChange={handleSearch}
                style={styles.searchInput}
            />

            <div style={styles.filterButtons}>
                <button onClick={() => handleFilterClick('This Week')} style={styles.filterButton}>
                    This Week
                </button>
                <button onClick={() => handleFilterClick('This Month')} style={styles.filterButton}>
                    This Month
                </button>
                <button onClick={() => handleFilterClick('Last Month')} style={styles.filterButton}>
                    Last Month
                </button>
            </div>

            {filteredMessages.length > 0 ? (
                <table style={styles.table}>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Message</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredMessages.map((message, index) => (
                            <tr key={index}>
                                <td>{message.date}</td>
                                <td>{message.title}</td>
                                <td>{message.text}</td>
                                <td>{message.status === 'sent' ? '✔️' : '❌'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p style={styles.noContent}>No content available</p>
            )}
            <div style={styles.pagination}>
                <span>Rows per page:</span>
                <span>1-5 of {filteredMessages.length}</span>
            </div>
        </div>
    );
};

const styles = {
    container: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        marginTop: '20px',
    },
    searchInput: {
        width: '100%',
        padding: '10px',
        margin: '10px 0',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    filterButtons: {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '10px',
        marginBottom: '10px',
    },
    filterButton: {
        padding: '10px 15px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
        backgroundColor: '#ddd',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
        marginTop: '10px',
    },
    noContent: {
        textAlign: 'center',
        fontSize: '16px',
        color: '#888',
        marginTop: '20px',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
};

const mapStateToProps = (state) => ({
    messages: state.broadcast.displayedMessages,
});

const mapDispatchToProps = (dispatch) => ({
    filterMessages: (filter) => dispatch(filterMessages(filter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviousMessages);

// PlanStatus.js

import React from 'react';
import { connect } from 'react-redux';
import { setActivePlan } from '../../actions/index.js';

const PlanStatus = ({ remainingMessages, activePlan, setActivePlan }) => {
    const handleUpgradeClick = () => {
        // Ovde mo�e� da doda� logiku za nadogradnju
        setActivePlan('PREMIUM PLAN');
        alert('Plan upgraded!');
    };

    return (
        <div style={styles.container}>
            <h3>Available Messages</h3>
            <p style={styles.subText}>for this month</p>
            <div style={styles.messageRow}>
                <span>Remaining messages</span>
                <span>{remainingMessages}</span>
            </div>

            <h3>Active Plan</h3>
            <div style={styles.planRow}>
                <span>{activePlan}</span>
                <button onClick={handleUpgradeClick} className="btn btn-yellow" style={styles.upgradeButton}>
                    UPGRADE
                </button>
            </div>
        </div>
    );
};

const styles = {
    container: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
       // maxWidth: '300px',
        margin: 'auto',
    },  
    subText: {
        color: '#888',
        fontSize: '12px',
        margin: '0 0 10px 0',
    },
    messageRow: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 0',
        backgroundColor: '#f1f1f1',
        padding: '10px',
        borderRadius: '4px',
    },
    planRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '20px',
    },
    upgradeButton: {
        padding: '10px 15px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
};

// Mapiranje Redux stanja na props
const mapStateToProps = (state) => ({
    remainingMessages: state.broadcast.remainingMessages,
    activePlan: state.broadcast.activePlan,
});

// Mapiranje akcija na props
const mapDispatchToProps = (dispatch) => ({
    setActivePlan: (plan) => dispatch(setActivePlan(plan)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanStatus);

import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux"
import {
    updateBarChartData, updateLineChartData, updateGaugeData, updatePaperGaugeData,
    updateDigitalGaugeData
} from "../../actions/index";
import CardList from "./card_list"
import Gauge from "../Gauge/Gauge"
import Banner from '../../assets/banner.svg';
import { LineChart, Line, ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Bar, Area } from 'recharts';
const data = [{ name: 'Nr Cards', uv: 400 }, { name: 'No Stamps', uv: 200 }
    , { name: '1 Stamp', uv: 20 }
    , { name: '2 Stamps', uv: 20 }
    , { name: '3 Stamps', uv: 60 }
    , { name: '4 Stamps', uv: 80 }
    , { name: '5 Stamps', uv: 20 }
    , { name: '6 Stamps', uv: 30 }];
// import PropTypes from "prop-types"

const data_line = [
    { type: 'paper', day: 1, uv: 17 },
    { type: 'digital', day: 1, uv: 20 },
    { type: 'paper', day: 2, uv: 12 },
    { type: 'digital', day: 2, uv: 22 },
    { type: 'paper', day: 3, uv: 18 },
    { type: 'paper', day: 4, uv: 18 },
    { type: 'digital', day: 4, uv: 20 },
    { type: 'paper', day: 5, uv: 18 },
    { type: 'digital', day: 5, uv: 70 },
    { type: 'paper', day: 6, uv: 18 },
    { type: 'digital', day: 6, uv: 50 },
    { type: 'paper', day: 7, uv: 18 },
    { type: 'digital', day: 7, uv: 30 },
];

const transformedData = data_line.reduce((acc, curr) => {
    const existing = acc.find(item => item.day === curr.day);
    if (existing) {
        existing[curr.type] = curr.uv;
    } else {
        acc.push({ day: curr.day, [curr.type]: curr.uv });
    }
    return acc;
}, []);

const formattedData = transformedData.map(item => ({
    day: item.day,
    paper: item.paper || 0,    // postavi na 0 ako nije definisano
    digital: item.digital || 0, // postavi na 0 ako nije definisano
}));

// Prilagodjeni Tooltip
const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const paper = payload[0].payload.paper;
        const digital = payload[0].payload.digital;
        const total = paper + digital;

        return (
            <div className="tooltip">
                <p>{`Day: ${payload[0].payload.day}`}</p>
                <p>{`Paper: ${paper}`}</p>
                <p>{`Digital: ${digital}`}</p>
                <p>{`Total: ${total}`}</p>
            </div>
        );
    }
    return null;
};
// Calculate totals for paper and digital
const totalPaper = formattedData.reduce((sum, item) => sum + item.paper, 0);
const totalDigital = formattedData.reduce((sum, item) => sum + item.digital, 0);
const total = totalPaper + totalDigital;
class Cards extends Component {
   
    renderSOmething() {
        const supplierId = this.props.match.params.supplierId === undefined ? 0 : this.props.match.params.supplierId;
        const page = this.props.match.params.page === undefined ? 1 : this.props.match.params.page
        return (
            <CardList supplierId={supplierId} page={page} />
        );
    }
    componentWillMount() {
        // Simulate API calls or fetch data dynamically
  
        this.props.updateBarChartData();
        this.props.updateLineChartData();
        this.props.updateGaugeData();
        this.props.updatePaperGaugeData();  // Fetch paper cards data
        this.props.updateDigitalGaugeData(); // Fetch digital cards data
    }
    render() {
        const { barChartData, lineChartData, gaugeData, paperGaugeData, digitalGaugeData } = this.props;
       // const totalPaper = lineChartData.reduce((sum, item) => sum + item.paper, 0);
       // const totalDigital = lineChartData.reduce((sum, item) => sum + item.digital, 0);
       // const total = totalPaper + totalDigital;

        return (
            <div>
                <div className="row">
                    <div className="col-6">
                        <ResponsiveContainer width="100%" height={230}>
                            <BarChart data={barChartData}>
                                <CartesianGrid
                                    stroke="#d3d3d3"
                                    vertical={false}
                                    horizontal={true} />
                                <XAxis dataKey="name" axisLine={false} />
                                <YAxis axisLine={false} />
                                <Tooltip />
                                <Bar dataKey="uv" fill="#C4A500" barSize={12} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div className="col-6" style={{ position: "relative" }}>
                        <div style={{ position: "absolute", top: 10, left: 100, zIndex: 1 }}>
                            <p style={{ fontWeight: "bold" }}>{`Total: ${total}`}</p>
                        </div>
                        <ResponsiveContainer width="100%" height={230}>
                            <LineChart data={lineChartData}>
                                <CartesianGrid stroke="#d3d3d3" vertical={false} horizontal={true} />
                                <XAxis dataKey="day" axisLine={false} />
                                <YAxis axisLine={false} />
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="paper" stroke="#C4A500" strokeWidth={2} dot={false} />
                                <Line type="monotone" dataKey="digital" stroke="#000000" strokeWidth={2} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Gauge dark_mode={false} title="Current Number of Paper Cards" data={paperGaugeData} />
                    </div>
                    <div className="col-6">
                        <Gauge dark_mode={true} title="Current Number of Digital Cards" data={digitalGaugeData} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Gauge dark_mode={true} title="Cards that got the broadcast message" data={gaugeData} />
                    </div>
                    <div className="col-6">
                        <Gauge dark_mode={false} title="Cards that got the broadcast message and received stamps afterwards" data={gaugeData} />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    barChartData: state.graphs.barChartData,
    lineChartData: state.graphs.lineChartData,
    gaugeData: state.graphs.gaugeData,
    paperGaugeData: state.graphs.paperGaugeData,  // New state for paper cards
    digitalGaugeData: state.graphs.digitalGaugeData, // New state for digital cards
});


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        updateBarChartData: updateBarChartData,
        updateLineChartData: updateLineChartData,
        updateGaugeData: updateGaugeData,
        updatePaperGaugeData: updatePaperGaugeData,
        updateDigitalGaugeData: updateDigitalGaugeData,
    }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(Cards);
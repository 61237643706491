import * as actions from "../actions/index"

const INITIAL_STATE = {
    saveUrl: "empty",
    success: null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${actions.GET_GOOGLE_PASS}_PENDING`:
            return { ...state };
        case `${actions.GET_GOOGLE_PASS}_FULFILLED`:
            console.log(action.payload.data);
            return {
                ...state,
                saveUrl: action.payload.data,
            }
        case `${actions.GET_GOOGLE_PASS}_REJECTED`:
            return {
                ...state,
                sageUrl: "error"
            }
            break;

        case `${actions.PUSH_GOOGLE_NOTIFICATION}_PENDING`:
            return { ...state };
        case `${actions.GET_GOOGLE_PASS}_FULFILLED`:
            console.log(action.payload.data);
            return {
                ...state,
                success: true,
            }
        case `${actions.GET_GOOGLE_PASS}_REJECTED`:
            return {
                ...state,
                success: false
            }
            break;
        default:
            return state;
    }
}
import { USERID_LOADED } from "../actions/index"

var INITIAL_STATE = {
    userId: null,
    message: null,
    status:null,
    statusClass:null
}

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        
        
        case `${USERID_LOADED}_REJECTED`:
            return {
                ...state,
                userId: null,
                message: action.payload.response.data,
                status: action.payload.response.status,
                statusClass: 'error'
            }
            break;


        case `${USERID_LOADED}_PENDING`:
            return { ...state };
        case `${USERID_LOADED}_FULFILLED`:
          
            return {
                ...state,
                userId: action.payload.data.userId,
                message: action.payload.data.message,
                status: action.payload.status,
                statusClass: 'ok'
            }
        default:
            
            return state;
    }
}
import * as actions from "../actions/index";
import { fetchUserMessages } from "../actions/index"; // Importujemo akciju da je možemo zvati iz reducera

const initialState = {
    messages: [],       // Lista poruka korisnika
    loading: false,     // Da li se trenutno učitava
    error: null         // Ako se desi greška
};

export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case `${actions.ADD_BROADCAST_MESSAGE}_PENDING`:
            return { ...state, loading: true };

        case `${actions.ADD_BROADCAST_MESSAGE}_FULFILLED`:
            return (dispatch) => { // Ovde pozivamo novu akciju direktno iz reducera
                dispatch(fetchUserMessages(action.payload.userId));
                return {
                    ...state,
                    loading: false
                };
            };

        case `${actions.ADD_BROADCAST_MESSAGE}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case `${actions.FETCH_USER_MESSAGES}_PENDING`:
            return { ...state, loading: true };

        case `${actions.FETCH_USER_MESSAGES}_FULFILLED`:
            return {
                ...state,
                messages: action.payload,
                loading: false
            };

        case `${actions.FETCH_USER_MESSAGES}_REJECTED`:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default:
            return state;
    }
};




// UserStats.js

import React from 'react';
import { connect } from 'react-redux';

const UserStats = ({ availableUsers }) => {
    return (
        <div style={styles.container}>
            <h3>User Stats</h3>
            <div style={styles.statsRow}>
                <span>Available Users</span>
                <span>{availableUsers}</span>
            </div>
        </div>
    );
};

const styles = {
    container: {
        border: '1px solid #ddd',
        padding: '15px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
      //  maxWidth: '200px',
        margin: 'auto',
    },
    statsRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '10px',
    },
};

// Mapiranje Redux stanja na props
const mapStateToProps = (state) => ({
    availableUsers: state.broadcast.availableUsers,
});

export default connect(mapStateToProps)(UserStats);

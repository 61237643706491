// reducers/broadcastReducer.js

import * as actions from '../actions';

const initialState = {
    title: '',
    message: '',
    availableUsers: 0,
    remainingMessages: 3,
    activePlan: 'STARTER PLAN',
    allMessages: [],
    displayedMessages: [],
    filter: 'This Week',
};

const broadcastReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.SET_TITLE:
            return {
                ...state,
                title: action.payload,
            };
        case actions.SET_MESSAGE:
            return {
                ...state,
                message: action.payload,
            };
        case actions.SEND_MESSAGE:
            console.log("Message Sent:", { title: state.title, message: state.message });
            return {
                ...state,
                title: '',
                message: '',
            };
        case actions.SET_AVAILABLE_USERS:
            return {
                ...state,
                availableUsers: action.payload,
            };
        case actions.SET_REMAINING_MESSAGES:
            return {
                ...state,
                remainingMessages: action.payload,
            };
        case actions.SET_ACTIVE_PLAN:
            return {
                ...state,
                activePlan: action.payload,
            };
        case actions.SET_MESSAGES:
            return {
                ...state,
                allMessages: action.payload,
                displayedMessages: action.payload,
            };
        case actions.FILTER_MESSAGES:
            const filteredMessages = state.allMessages.filter((message) => {
                // Primenite filtriranje po vremenskom periodu ovde, ovo je samo osnovni primer
                if (action.payload === 'This Week') {
                    // Dodaj logiku za filtriranje po ovoj nedelji
                } else if (action.payload === 'This Month') {
                    // Dodaj logiku za filtriranje po ovom mesecu
                } else if (action.payload === 'Last Month') {
                    // Dodaj logiku za filtriranje za pro�li mesec
                }
                return true;
            });
            return {
                ...state,
                displayedMessages: filteredMessages,
                filter: action.payload,
            };
        default:
            return state;
    }
};

export default broadcastReducer;
